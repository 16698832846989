<template>
    <div class="wrapper">
        <div class="header">
            <el-row :gutter="20">
                <el-col :span="3">
                    <el-button type="primary" icon="el-icon-plus" size='medium' @click="addMoniter">添加监控</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="main">
            <el-tabs v-model="activeName" type="border-card" editable @edit="handleTabsEdit">
                <el-tab-pane :key="item.name" v-for="(item, index) in moniterData" :label="item.monitorName"
                    :name="item.id">
                    <div style="width:100%;text-align: center;">
                        <iframe width="100%" :height="videoHeight" id="ysOpenDevice"
                            :src="'https://open.ys7.com/ezopen/h5/iframe?url=' + item.monitorUrl + '&autoplay=1&accessToken=' + item.accessToken"></iframe>
                    </div>

                </el-tab-pane>
            </el-tabs>

        </div>

        <el-dialog title="添加监控" :visible="dialogTableVisible" :show-close='false' :close-on-press-escape='false' width="40%"
            append-to-body v-if="dialogTableVisible">
            <el-form :model="form" label-width="auto" class="demo-ruleForm" label-position="right" size="small" ref="form"
                :rules="rules">
                <el-form-item label="监控名称:" prop="monitorName">
                    <el-input v-model="form.monitorName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="链接地址：" prop="monitorUrl">
                    <el-input v-model="form.monitorUrl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="AppKey：" prop="appKey">
                    <el-input v-model="form.appKey" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Secret：" prop="secret">
                    <el-input v-model="form.secret" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="封面图" prop="beforeImgs">
                    <upload-img :limit="1" :uploadLists="beforeImgsList" @uploadChange="onBeforeImg"></upload-img>
                </el-form-item> -->

            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center;">
                <el-button type="primary" @click="commitBtn('form')" size="medium">确 定</el-button>
                <el-button @click="closeDialog('form')" size="medium">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from 'axios'
import api from '@/store/API/api'
import UploadImg from '@/components/UploadImg.vue'

export default {
    name: 'Moniter',
    components: {
        UploadImg
    },
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            moniterData: [],
            beforeImgsList: [],
            accessToken: "",
            videoHeight: "400",
            form: {
                cmId: this.id,
                monitorName: '',
                monitorUrl: '',
                beforeImgs: ''
            },
            activeName: '',
            dialogTableVisible: false,
            rules: {
                monitorName: [{ required: true, message: '请填写监控名称', trigger: 'blur' }],
                monitorUrl: [{ required: true, message: '请填写链接地址', trigger: 'blur' }],
                appKey: [{ required: true, message: '请填写appKey', trigger: 'blur' }],
                secret: [{ required: true, message: '请填写secret', trigger: 'blur' }],
                // beforeImgs:[ { required: true, message: '请上传图片', trigger: 'blur' }],

            }
        }
    },
    created() {
        console.log('111101111111111111', document.documentElement.clientWidth)
        this.videoHeight = (document.documentElement.clientWidth * 0.35 - 40) * 2 / 3
        //this.queryList()
    },
    methods: {
        ...mapActions([
            "queryMoniterList",
            'addMoniters',
            'delMoniter',
            'YSAccessToken'

        ]),
        imghandle(row) {
            // window.open(row.monitorUrl)
            window.open(row.monitorUrl, "_blank");
        },
        //添加监控
        addMoniter() {
            this.dialogTableVisible = true
        },
        //删除监控
        handleRemove(id) {
            let params = {
                id: id
            }
            this.delMoniter(params).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$message.success('删除成功')
                    this.queryList()
                }

                console.log(res);
            })

        },
        queryList() {
            let params = {
                cmId: this.id
            }
            this.moniterData = []
            this.queryMoniterList(params).then(res => {
                console.log(res);
                this.moniterData = res.data
                this.activeName = this.moniterData[0].id
                this.moniterData.forEach(item => {
                    this.getAccessToken(item.id).then((res) => {
                        this.$set(item, 'accessToken', res)
                    })
                })

            })
        },
        onBeforeImg(data) {
            console.log(data);
            this.form.beforeImgs = data.join(',')
        },
        closeDialog(formName) {
            this.$refs[formName].resetFields();
            this.form.beforeImgs = []
            this.dialogTableVisible = false
        },
        commitBtn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.form
                    }
                    this.addMoniters(params).then(res => {
                        console.log(res);
                        if (res.code == 200) {
                            this.$message.success('添加成功')
                            this.dialogTableVisible = false
                            this.$refs[formName].resetFields();
                            this.queryList()
                        }
                    })
                } else {
                    return false;
                }
            });
        },

        handleTabsEdit(targetName, action) {
            if (action === 'add') {
                this.dialogTableVisible = true
            }
            if (action === 'remove') {
                this.$confirm('此操作将永久删除监控，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.handleRemove(targetName)
                })
            }
        },
        getAccessToken(id) {
            let params = {
                id: id
            }
            return new Promise((resolve, reject) => {
                this.YSAccessToken(params).then(res => {
                    resolve(res.data);
                }).catch(error => {
                    reject(error);
                });
            });
        }

    }

}
</script>

<style lang="scss" scoped>
.wrapper {
    overflow-y: auto;
}

.main {
    margin: 20px 0px;
}

.imgDivs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.imgdiv {
    margin-right: 10px;
}

.imgIcon {
    position: absolute;
    top: 0px;
    right: 13px;

}

.imgClick {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 20px;
    left: 20px;
    // background-color: red;
    z-index: 200;
}

::v-deep(.el-tabs__new-tab) {
    display: none;
}

::v-deep(.el-tabs__nav) {
    display: flex;
    justify-content: end;
    min-width: 100%;
}
</style>

